<template>
  <div>
    <div class="progress-bar">
      <span
        class="progress-fill"
        ref="fill"
        :style="[{ 'background-color': color }]"
      >
        <span v-show="btnshow" ref="filltext" class="progress-text"
          >{{ value }}%</span
        >
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "loading",
  props: {
    color: {
      type: String,
      default: "hsl(260, 90%, 70%)",
    },
    value: {
      type: [String, Number],
      default: 10,
    },
    btnshow: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => this.render());
  },
  methods: {
    render() {
      this.$refs.fill.style.width = this.value + "%";
    },
  },
};
</script>
<style lang="less">
.progress-bar {
  width: 100%;
  background-color: rgb(232, 232, 232);
  height: 3px;
  // overflow: hidden;
  position: relative;
  .progress-fill {
    display: block;
    height: 100%;
    max-width: 100%;
    width: 0;
    transition: 2s width ease-in-out;
  }
  .progress-text {
    display: inline-block;
    position: relative;
    top: -10px;
    left: -20px;
    z-index: 9999;
    width: 40px;
    height: 20px;
    border-radius: 12px;
    background: rgba(255, 255, 255);
    border: 1px solid rgba(220, 223, 230, 1);
    /*内部文字 */
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    color: rgba(96, 98, 102, 1);
    text-align: center;
    margin-left: 100%;
  }
}
</style>