var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{staticClass:"page-box task-list-page",attrs:{"gutter":20}},[_c('el-col',[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
        {
          label: '订单管理',
          to: '/order',
        } ]}})],1),_c('el-col',[_c('el-card',{staticClass:"grid-card",attrs:{"shadow":"never"}},[_c('SchemaTable',{ref:"schematableref",attrs:{"schema":_vm.schema,"model":_vm.dataModel,"search":_vm.search,"filters":_vm.filters,"queryConf":_vm.queryConf,"hasReset":_vm.hasReset,"formConf":_vm.formConf,"layout":"card","queryLayout":"prependBtnSearch"},on:{"rowClick":_vm.onOrderRowClick},scopedSlots:_vm._u([{key:"cardContainer",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"card-container-self",on:{"mouseover":function($event){return _vm.mouseoverStyle(row)},"mouseout":function($event){return _vm.mouseoutStyle(row)}}},[_c('div',{staticClass:"order-finish-status"},[_c('span',[_vm._v("生产数量: "+_vm._s(row.productCount))]),_c('i',{staticClass:"spacing"}),_c('span',[_vm._v("进行中:"+_vm._s(row.productCount - row.finshCount < 0 ? 0 : row.productCount - row.finshCount))]),_c('i',{staticClass:"spacing"}),_c('span',[_vm._v("已完成: "+_vm._s(row.finshCount))])]),_c('div',{staticClass:"order-progress"},[_c('Progress',{attrs:{"color":"#006EBB","value":((row.finshCount * 100) / row.productCount).toFixed(1),"btnshow":_vm.active === row.id}})],1),_c('div',{staticClass:"order-name"},[_c('span',[_vm._v(_vm._s(row.name))])]),_c('div',{staticClass:"order-id"},[_vm._v("编号"+_vm._s(row.id))]),_c('div',[_c('el-tag',{staticClass:"order-status",staticStyle:{"background-color":"#006ebb"}},[_vm._v(_vm._s(row.stageName))]),_c('el-tag',{staticClass:"order-status",style:({ 'background-color': row.stateColor })},[_vm._v(_vm._s(row.stateName))])],1),_c('div',{staticClass:"order-createtime"},[_vm._v("创建时间"+_vm._s(row.createdTime))])])]}},{key:"actionBtns",fn:function(ref){
      var row = ref.row;
return [_c('el-link',{staticClass:"action-link",attrs:{"type":"primary"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goSync(row)}}},[_vm._v(" 同步 ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }