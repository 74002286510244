<template>
  <el-row :gutter="20" class="page-box task-list-page">
    <el-col>
      <Breadcrumb
        :home="false"
        :breadcrumbs="[
          {
            label: '订单管理',
            to: '/order',
          },
        ]"
      />
    </el-col>
    <el-col>
      <el-card class="grid-card" shadow="never">
        <SchemaTable
          ref="schematableref"
          :schema="schema"
          :model="dataModel"
          :search="search"
          :filters="filters"
          :queryConf="queryConf"
          :hasReset="hasReset"
          :formConf="formConf"
          layout="card"
          queryLayout="prependBtnSearch"
          @rowClick="onOrderRowClick"
        >
          <template #cardContainer="{ row }">
            <div
              class="card-container-self"
              @mouseover="mouseoverStyle(row)"
              @mouseout="mouseoutStyle(row)"
            >
              <div class="order-finish-status">
                <span>生产数量: {{ row.productCount }}</span>
                <i class="spacing"></i>
                <span>进行中:{{ row.productCount - row.finshCount < 0 ? 0 : row.productCount - row.finshCount }}</span>
                <i class="spacing"></i>
                <span>已完成: {{ row.finshCount }}</span>
              </div>
              <div class="order-progress">
                <Progress
                  color="#006EBB"
                  :value="
                    ((row.finshCount * 100) / row.productCount).toFixed(1)
                  "
                  :btnshow="active === row.id"
                ></Progress>
              </div>
              <div class="order-name">
                <span>{{ row.name }}</span>
              </div>
              <div class="order-id">编号{{ row.id }}</div>
              <div>
                <el-tag
                  class="order-status"
                  style="background-color: #006ebb"
                  >{{ row.stageName }}</el-tag
                >
                <el-tag
                  class="order-status"
                  :style="{ 'background-color': row.stateColor }"
                  >{{ row.stateName }}</el-tag
                >
              </div>
              <div class="order-createtime">创建时间{{ row.createdTime }}</div>
            </div>
          </template>
          <template #actionBtns="{ row }">
            <el-link
              type="primary"
              class="action-link"
              @click.native.stop="goSync(row)"
            >
              同步
            </el-link>
          </template>
        </SchemaTable>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { SchemaTable, DataModel } from "enn-schema-table";
import schema from "./order.schema.json";
import { uris, attachId } from "./../../api/uri";
import Breadcrumb from "enn-breadcrumb";
import Progress from "./progress/index.vue";
import axios from "axios";
import moment from "moment";
import { getUser } from "../../utils/info";

const stageMap = {
  1: "量产",
  2: "试产",
  3: "测试",
};
const stateLabelColorMap = {
  1: "#F9BE00", // 未开始
  3: "#006EBB", // 进行中（未完成）
  2: "#6EB92C", // 已完成
};
export default {
  components: {
    SchemaTable,
    Breadcrumb,
    Progress,
  },
  data() {
    const userinfo = getUser();
    return {
      active: 0,
      search: "请输入订单名称或者编号",
      filters:
        userinfo?.roleType === "super"
          ? ["stage", "state", "factoryId"]
          : ["stage", "state"],
      schema,
      hasReset: false,
      serverOptions: [],
      queryConf: { closePrefix: true },
      dataModel: new DataModel({
        getListApi: `${uris.order}`,
        getListMap(item) {
          item.productId = item.product.name;
          item.createdTime = moment(item.created_at).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          item.stateName = item.state === 1 ? "未完成" : "已完成";
          item.stateColor = stateLabelColorMap[item.state + ""]
            ? stateLabelColorMap[item.state + ""]
            : stateLabelColorMap["1"];

          if (item.factory) {
            item.factoryId = item.factory.name;
          }
          item.stageName = stageMap[item.stage];
          return item;
        },
        getApi: `${uris.order}${attachId}`,
        getMap(res) {
          res.productName = res.product.name;
          if (res.factory) {
            res.factoryName = res.factory.name;
            res.factoryId = res.factory.id;
          }
          res.productId = res.product.id;
          res.stageName = stageMap[res.stage];
          res.stage = res.stage.toString();
          return res;
        },
        deleteApi: `${uris.order}${attachId}`,
        createApi: uris.order,
        updateApi: `${uris.order}${attachId}`,
        query: { pageNumber: 1, pageSize: 9 },
        axiosConfig: {
          timeout: 120000,
        },
      }),
      checkEiot: {},
      modelMap: {},
    };
  },
  computed: {
    formConf() {
      return {
        getServerOptions: this.getServerOptions.bind(this),
        checkEiot: this.checkEiot,
        modelMap: this.modelMap,
      };
    },
  },
  methods: {
    async goSync(row) {
      axios
        .post(`${uris.syncOrder}`, {
          orderId: row.id,
          factoryId: row.factory.id,
        })
        .then((res) => {
          if (res.data.code !== 0) {
            this.$message({
              type: "error",
              message: res.data.message,
            });
            return;
          }
          this.$message({
            type: "success",
            message: "同步成功",
          });
        });
    },
    onOrderRowClick(order) {
      this.$router.push({
        name: "order-detail",
        params: { id: order.id },
      });
    },
    async getServerOptions(scenario) {
      if (this.serverOptions && this.serverOptions.length) {
        return this.serverOptions;
      }
      const optionMd = new DataModel({
        getApi: "/api/v1/product?pageSize=60",
      });
      const res = await optionMd.get();
      let options = await this.optionsFormat(res.list);
      const checkEiot = {},
        modelMap = {};
      options.forEach((item) => {
        checkEiot[item.value] = item.isEiot;
        modelMap[item.value] = item.model;
      });
      // console.log(checkEiot);
      // console.log(options)
      this.checkEiot = checkEiot;
      this.modelMap = modelMap;
      this.serverOptions = options;
      return options;
    },
    async optionsFormat(list, valueKey = "id", labelKey = "name") {
      if (!Array.isArray(list)) {
        return [];
      }
      let str = JSON.stringify(list)
        .replace(new RegExp(`"${valueKey}":`, "g"), '"value":')
        .replace(new RegExp(`"${labelKey}":`, "g"), '"label":');
      return JSON.parse(str);
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    mouseoverStyle(item) {
      this.active = item.id;
    },
    mouseoutStyle() {
      this.active = "";
    },
  },
};
</script>

<style  lang="less" scoped>
.order-finish-status {
  font-size: 12px;
  font-weight: 400;
  color: #606266;
  margin-bottom: 12px;
  .spacing {
    border: 1px solid #dcdfe6;
    margin: 0 10px;
  }
}

.order-progress {
  margin: 0 -16px 16px;
}
.order-name {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  line-height: 20px;
  margin-bottom: 12px;
}

.order-id {
  font-size: 12px;
  font-weight: 400;
  color: #909399;
  line-height: 18px;
  margin-bottom: 12px;
}

.order-status {
  margin-right: 10px;
  height: 22px;
  line-height: 22px;
  color: #fff;
}

.order-createtime {
  font-size: 12px;
  font-weight: 400;
  color: #606266;
  line-height: 18px;
  margin-top: 12px;
}
</style>